var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "sns-page" }, [
    _c(
      "section",
      {
        staticClass: "border-bottom bg-lighter md-up:sticky-top",
        staticStyle: { top: "70px" }
      },
      [
        _c("div", { staticClass: "navbar bg-white p-3" }, [
          _c(
            "div",
            { staticClass: "d-flex justify-content-between w-100" },
            [
              _c("mega-input", {
                staticClass: "mb-0",
                attrs: {
                  placeholder: _vm.$t("search_placeholder"),
                  button: { icon: "i-search", click: _vm.onSearch }
                },
                on: { keyup: _vm.onKeyUp },
                model: {
                  value: _vm.query,
                  callback: function($$v) {
                    _vm.query = $$v
                  },
                  expression: "query"
                }
              }),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "btn btn-light",
                  attrs: {
                    to: {
                      name: "Create_Sn"
                    }
                  }
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(_vm.$t("sn_create")) + "\n        "
                  )
                ]
              )
            ],
            1
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c("section", [
      _c(
        "div",
        { staticClass: "container py-5" },
        [
          _vm.list.length
            ? _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.list, function(sn, i) {
                  return _c(
                    "div",
                    {
                      key: i,
                      staticClass:
                        "xs:col-12 sm:col-6 md:col-4 lg:col-4 xl-up:col-3 mb-5"
                    },
                    [_c("sn-card", { attrs: { sn: sn } })],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c("mega-loading", {
            attrs: { identifier: _vm.loadingId },
            on: { infinite: _vm.loadPage }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }