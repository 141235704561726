<template>
  <mega-card class="rounded h-100 mb-0">
    <div class="navbar bg-white">
      <router-link
        :to="{ name: 'Sn', params: { id: sn.id } }"
        class="container-fluid w-80 navbar-label text-dark py-3 m-0"
        style="overflow: hidden;"
      >
        <mega-image
          style="flex-shrink: 0; overflow: hidden;"
          class="rounded navbar-avatar mr-3"
          ratio="1x1"
          small
          :src="sn.avatar"
        />

        <div class="content">
          <small class="strong text-overflow"
            >{{ sn.name }} ({{ sn.name_ru }})</small
          >
          <small class="text-muted text-overflow">{{
            "@" + sn.username
          }}</small>
        </div>
      </router-link>
      <div class="p-3 check-wrapper" @click="select">
        <IconBase
          :height="'32'"
          :width="'32'"
          :viewBox="'0 0 36 36'"
          class="mr-0 cursor-pointer"
        >
          <FullfilledCheck v-if="selected" />
          <EmptyCheck v-else />
        </IconBase>
      </div>
    </div>
  </mega-card>
</template>

<script>
import EmptyCheck from "../../../../components/icons/set/EmptyCheck.vue";
import FullfilledCheck from "../../../../components/icons/set/FullfilledCheck.vue";
import IconBase from "../../../../components/icons/IconBase.vue";

export default {
  name: "SnCard",
  components: {
    EmptyCheck,
    FullfilledCheck,
    IconBase
  },
  props: {
    sn: Object
  },
  data() {
    return {
      arrLikes: 0,
      arrTransitions: 0,
      arrRoundViews: 0,
      modal: false
    };
  },
  computed: {
    selected() {
      if (this.$store.state.selectedSn) {
        return this.$store.state.selectedSn.id === this.sn.id;
      }
      return false;
    }
  },
  methods: {
    select() {
      if (
        this.$store.state.selectedSn &&
        this.$store.state.selectedSn.id === this.sn.id
      ) {
        this.$store.commit("setSelectedSn", null);
      } else {
        this.$store.commit("setSelectedSn", this.sn);
      }
    }
  }
};
</script>
