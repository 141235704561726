var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mega-card", { staticClass: "rounded h-100 mb-0" }, [
    _c(
      "div",
      { staticClass: "navbar bg-white" },
      [
        _c(
          "router-link",
          {
            staticClass: "container-fluid w-80 navbar-label text-dark py-3 m-0",
            staticStyle: { overflow: "hidden" },
            attrs: { to: { name: "Sn", params: { id: _vm.sn.id } } }
          },
          [
            _c("mega-image", {
              staticClass: "rounded navbar-avatar mr-3",
              staticStyle: { "flex-shrink": "0", overflow: "hidden" },
              attrs: { ratio: "1x1", small: "", src: _vm.sn.avatar }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("small", { staticClass: "strong text-overflow" }, [
                _vm._v(
                  _vm._s(_vm.sn.name) + " (" + _vm._s(_vm.sn.name_ru) + ")"
                )
              ]),
              _vm._v(" "),
              _c("small", { staticClass: "text-muted text-overflow" }, [
                _vm._v(_vm._s("@" + _vm.sn.username))
              ])
            ])
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "p-3 check-wrapper", on: { click: _vm.select } },
          [
            _c(
              "IconBase",
              {
                staticClass: "mr-0 cursor-pointer",
                attrs: { height: "32", width: "32", viewBox: "0 0 36 36" }
              },
              [_vm.selected ? _c("FullfilledCheck") : _c("EmptyCheck")],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }