<template>
  <main class="sns-page">
    <section
      class="border-bottom bg-lighter md-up:sticky-top"
      style="top: 70px;"
    >
      <div class="navbar bg-white p-3">
        <div class="d-flex justify-content-between w-100">
          <mega-input
            class="mb-0"
            @keyup="onKeyUp"
            :placeholder="$t('search_placeholder')"
            v-model="query"
            :button="{ icon: 'i-search', click: onSearch }"
          />

          <router-link
            :to="{
              name: 'Create_Sn'
            }"
            class="btn btn-light"
          >
            {{ $t("sn_create") }}
          </router-link>
        </div>
      </div>
    </section>

    <section>
      <div class="container py-5">
        <div class="row" v-if="list.length">
          <div
            class="xs:col-12 sm:col-6 md:col-4 lg:col-4 xl-up:col-3 mb-5"
            v-for="(sn, i) in list"
            :key="i"
          >
            <sn-card :sn="sn" />
          </div>
        </div>

        <mega-loading
          :identifier="loadingId"
          @infinite="loadPage"
        ></mega-loading>
      </div>
    </section>
  </main>
</template>

<script>
import SnCard from "./components/sn-card.vue";

export default {
  name: "Sns",
  computed: {
    list() {
      return this.$root.list;
    }
  },
  data() {
    return {
      loadingId: +new Date(),
      query: ""
    };
  },
  created() {
    if (!this.$root["history"].includes(this.$route.name))
      this.$root["list"] = [];
  },
  mounted() {
    this.$navbar.name = this.$t("social_networks");
  },
  methods: {
    loadPage($state) {
      this.loaded = false;

      let params = {
        limit: 60,
        offset: this.list.length
      };

      this.$api.v2
        .get("/sn/list", { params })
        .then(({ data }) => {
          if (data.length) {
            setTimeout(() => {
              if ($state) $state.loaded();
            }, 500);
            this.$root.list.push(...data);
          } else $state.complete();
        })
        .catch(() => {
          this.$alert.danger("Oops.. Server error");
        });
    },
    onKeyUp(event) {
      if (event.key === "Enter") this.onSearch();
    },
    onSearch() {
      const params = {
        query: this.query
      };

      this.$api.v2.get("/sn/search", { params }).then(({ data }) => {
        this.$root.list = data;
      });
    }
  },
  components: {
    SnCard
  }
};
</script>
